@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Outfit:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
  font-family: "Inter", sans-serif !important;
}

.headingDashboard h4 {
  color: #475467 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding-top: 3px !important;
}

.dashboardCard h6 {
  font-size: 30px !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}

.dashboardCard h5 {
  font-size: 14px !important;
  color: #475467 !important;
  font-family: "Inter" !important;
  padding-bottom: 4px;
  padding-top: 4px;
}

.dashboardCard {
  border: 1px solid #eaecf0 !important;
  height: 114px !important;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border-radius: 12px !important;
  padding: 20px 20px !important;
}

.headingDashboard h2 {
  font-size: 30px !important;
  font-family: "Inter" !important;
  color: #101828;
  font-weight: 600 !important;
}

.signupForm input {
  border: 1px solid #d0d5dd;
  height: 44px !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.signupForm h5 {
  font-size: 14px !important;
  font-family: "Inter";
  color: #344054 !important;
}

.signinForm h1 {
  font-size: 36px !important;
  font-family: "Inter" !important;
  color: #101828 !important;
}

.signupForm input::placeholder {
  color: #667085;
  font-family: "Inter", sans-serif !important;
}

.signBtn {
  height: 44px !important;
  background: #155eef !important;
  border: 1px solid #155eef !important;
  font-size: 16px !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}

.text-left.passwords {
  font-family: "Inter" !important;
  margin: 26px 0 !important;
  text-align: center !important;
}

.text-left.passwords span {
  font-family: "Inter" !important;
}

.signupForm input {
  font-weight: 400 !important;
  color: #000;
  text-transform: none !important;
  outline: 0 !important;
}

input {
  font-family: "Inter" !important;
}

.ant-tooltip {
  font-family: "Inter" !important;
}

.ant-tooltip-inner {
  font-size: 12px !important;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls {
  z-index: 9999999;
  position: absolute;
  bottom: 14px;
  width: 96%;
  left: 8px;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.custom-steps .ant-steps-item-content {
  background: transparent !important;
}

.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.custom-drawer .ant-drawer-header {
  display: flex;
  justify-content: space-between;
  /* Align items to opposite ends */
}

.custom-drawer .ant-drawer-title {
  order: 2;
  /* Move the title to the right */
}

.custom-modal .ant-modal-content {
  padding: 0;
}

.ant-dropdown-menu {
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
  padding: 0 !important;
  box-shadow:
    0px 4px 6px -2px #10182808,
    0px 12px 16px -4px #10182814 !important;
  width: auto;
}

.ant-dropdown-menu-item {
  padding: 0 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: red;
}

.generateTitle h3 {
  color: #475467;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}

.searchBoxed .ant-input-affix-wrapper input::placeholder {
  color: #667085 !important;
  font-family: "Inter" !important;
}

.tablesBox {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
}

.tablesBox .ant-table-wrapper thead tr th {
  background: #f9fafb !important;
  padding-top: 10px;
  padding-bottom: 11px;
  font-family: "Inter" !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.tablesBox table tbody tr td {
  padding: 25px 18px !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
}

.tablesBox table tbody tr td:nth-child(1) {
  color: #101828 !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}

.tablesBox table tbody tr td:nth-child(2) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
}

.tablesBox table tbody tr td:nth-child(4) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
}

.tablesBox table tbody tr td:nth-child(5) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}
.paginationText {
  font-family: "Inter" !important;
}
.commisionSearch {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}
.commisionTable {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
}
.commisionTable table thead tr th:nth-child(1) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(3) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(4) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(5) img {
  opacity: 0 !important;
}
.commisionTable table thead tr th:nth-child(6) img {
  opacity: 0 !important;
}
.tabsSetting button span {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Inter" !important;
}
.commisionTable table thead tr th {
  background: #f9fafb !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  padding: 12px 16px !important;
}
.grid.leftSides h5 {
  font-size: 18px;
  font-family: "Inter";
  font-weight: 600 !important;
}
.grid.leftSides h6 {
  font-size: 14px;
  font-family: "Inter";
  color: #475467;
  padding-top: 2px;
}
.rightSides {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
  padding: 0 !important;
}
.firstForm {
  padding: 20px 20px 0;
}
.secondForm {
  padding: 0px 20px 20px;
}
.firstForm img {
  position: absolute;
  z-index: 9;
  top: 60px;
  left: 27px;
}
.secondForm img {
  position: absolute;
  z-index: 9;
  top: 40px;
  left: 27px;
}
.anticon.anticon-eye.ant-input-password-icon svg {
  width: 16px;
  height: 15px;
  color: #667085 !important;
}
.employeHeader button span {
  font-size: 16px !important;
  font-family: "Inter" !important;
  color: #344054 !important;
  font-weight: 600 !important;
}
.forgotText {
  font-family: "Inter" !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  padding-bottom: 12px !important;
}
.resets {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.forgotbtn {
  font-family: "Inter" !important;
  font-size: 16px !important;
  border-radius: 8px !important;
}
.checkEmail h1 {
  font-size: 30px !important;
  font-family: "Inter";
  font-weight: 600 !important;
  padding-bottom: 6px !important;
}
.checkEmail h6 {
  font-family: "Inter" !important;
  color: #475467 !important;
  font-weight: 400 !important;
}
.newPassword {
  font-family: "Inter" !important;
  color: #101828 !important;
  padding-bottom: 12px;
}
.lineGraph .ant-picker {
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "Inter";
  color: #667085 !important;
  border: 1px solid #d0d5dd !important;
  height: 40px !important;
}
.lineGraph .ant-picker-suffix {
  background-image: url(./assets/images/date.png);
  width: 18px;
  height: 20px;
  margin: 0 5px 0 0 !important;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
}
.lineGraph .ant-picker input {
  font-weight: 600 !important;
}
.ant-picker-panel-container.ant-picker-year-panel-container {
  box-shadow: 0px 8px 8px -4px #10182808;
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #f2f4f7;
  width: auto;
  border-radius: 8px;
}
.ant-picker-panel-layout {
  justify-content: center;
  padding: 15px 15px 8px !important;
}
.ant-picker-year-panel {
  width: 320px !important;
}
.ant-picker-year-panel .ant-picker-header {
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Inter" !important;
}
td.ant-picker-cell.ant-picker-cell-disabled {
  font-size: 14px;
  color: #667085;
  opacity: 0.3;
  font-weight: 400;
  font-family: "Inter";
}
.ant-picker-dropdown .ant-picker-cell-disabled::before {
  background-color: transparent !important;
}
.ant-picker-super-prev-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgBfZA9DkBAEIXfrp/aERzBFdyEQq/XaESrlCgcxRE4giPoJZjdLIk16zU7s9/Le8kIMMrKJgmB8QRyn4MBMBGEh32THDRr2nf1Klxw6KpFDeIPakNWtnGAY+agkiQYPVGM9H9BFfRMajntintwmV7JnOlTbZukbTDRKeFVXfICA/87B36br4kAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  width: 12px !important;
  background-size: contain;
  height: 12px !important;
  transform: rotate(360deg) !important;
}
.ant-picker-super-next-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACMSURBVHgBdZAxDkBAEEX/EGpHcARXcBMKvV6jEa1S4hCO4AgcwRH0EszISuxkd4rdnbw3s5Ohou7TGNd8A+U0NBtUBBGuhGFKwFLVXaYFksOARRKW838n+h4+if7tXBLpP7UUaOE0N0OEOA9LKLg64mqT5uPQ7uSD1pA++AqySV7W6oIS7yYJ9+6CEg802z+7tgYOLgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  width: 12px !important;
  background-size: contain;
  height: 12px !important;
  transform: rotate(360deg) !important;
}
span.ant-picker-super-next-icon::before {
  display: none !important;
}
.bg-whites {
  font-family: "Inter";
  font-size: 14px;
  background: #fff;
  color: #344054 !important;
  border-left: 0 !important;
  font-weight: 600 !important;
  height: 40px !important;
}
.custom-calendar-class .ant-picker-panel-layout {
  padding: 0 14px !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container {
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #f2f4f7 !important;
  border-radius: 8px !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  .ant-picker-presets {
  width: 170px !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  ul {
  padding-left: 0 !important;
  border-right: 1px solid #eaecf0 !important;
}
.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  li {
  color: #344054 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}
.custom-calendar-class .ant-picker-panels {
  padding-top: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.custom-calendar-class .ant-picker-header {
  border-bottom: 0 !important;
}
.custom-calendar-class .ant-picker-month-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
}
.custom-calendar-class .ant-picker-content thead tr th {
  color: #344054 !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.custom-calendar-class .ant-picker-content td {
  padding: 0 !important;
}
.custom-calendar-class td.ant-picker-cell .ant-picker-cell-inner {
  color: #667085;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.yearPicker .ant-picker-content .ant-picker-cell-inner {
  color: #344054;
  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}
.custom-calendar-class
  .ant-picker-cell.ant-picker-cell-range-start.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  border-radius: 100px !important;
  background-color: #155eef !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: "." !important;
  border: 0 !important;
  color: #155eef;
  position: absolute;
  top: 10px;
  font-size: 40px !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #155eef !important;
  border-radius: 100px !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
  height: 40px;
}
.custom-calendar-class .ant-picker-footer-extra .w-full.py-4 {
  display: flex !important;
  align-items: baseline !important;
  padding: 10px 0 0 !important;
}
.custom-calendar-class .ant-input::placeholder {
  color: #667085 !important;
}
.borderss span {
  font-family: "Inter" !important;
  font-size: 14px !important;
  white-space: nowrap !important;
}
.tabsSetting {
  width: 100% !important;
  justify-content: space-between !important;
}
.borderss {
  padding-left: 11px !important;
}
.custom-calendar-class .ant-input {
  border: 1px solid #d0d5dd !important;
  color: #101828;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 44px !important;
  font-family: "Inter" !important;
  width: 136px;
  margin-right: 0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
}
span.sepeartor {
  display: inline-flex;
  color: #667085;
  margin: 0 8px;
  font-size: 31px;
  position: relative;
  top: 3px;
}
.custom-calendar-class .ant-picker-footer-extra .ant-btn-default {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  border-radius: 8px !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  color: #344054 !important;
  font-weight: 600;
}
.custom-calendar-class .ant-picker-footer-extra .ant-btn-primary {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  font-family: "Inter" !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600;
  background-color: #155eef !important;
}
.bg-transparents {
  background: #f9fafb !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px !important;
  color: #1d2939 !important;
  font-weight: 600 !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
span.ant-picker-super-next-icon::after {
  display: none !important;
}
span.ant-picker-super-prev-icon::before {
  display: none !important;
}
span.ant-picker-super-prev-icon::after {
  display: none !important;
}
td.ant-picker-cell.ant-picker-cell-in-view {
  font-family: "Inter" !important;
}
.lineGraph .anticon.anticon-calendar {
  display: none !important;
}
.lineGraph .ant-picker-input {
  flex-flow: row-reverse !important;
}
.checkEmail span.ant-typography {
  font-family: "Inter" !important;
}
.newText {
  font-family: "Inter";
  color: #475467;
  font-size: 16px;
}
.checkEmail h6 span {
  font-weight: 500 !important;
}
.passwords img {
  position: absolute;
  z-index: 9;
  top: 40px;
  left: 27px;
}
.pageProfile {
  border-top: 1px solid #eaecf0 !important;
  height: 73px !important;
  padding-right: 20px !important;
}
.editBtn {
  width: 80px !important;
  height: 40px !important;
  font-family: "Inter" !important;
  padding: 0 !important;
  color: #344054 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}
.thirdForm {
  padding: 0 20px !important;
}
.deleteBtn {
  background: #155eef !important;
  width: 109px !important;
  height: 40px !important;
  border: 1px solid #155eef !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 8px !important;
}
.firstForm .ant-input-affix-wrapper {
  padding: 0 !important;
  border: 1px solid #d0d5dd !important;
}
.mainPassword .ant-input-affix-wrapper {
  padding: 0 15px 0 30px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
}
.passwords {
  padding: 0 22px !important;
}
body .mainPassword .ant-input-affix-wrapper input {
  color: #101828 !important;
  border: 0 !important;
}
body .mainPassword .ant-input-affix-wrapper input::placeholder {
  color: #667085 !important;
}
.rightSides input {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  font-family: "Inter";
  color: #101828;
}
.profileSection {
  width: 64px !important;
  height: 64px !important;
  min-width: 64px !important;
}
.uploadingFile {
  border: 1px solid #eaecf0 !important;
  border-radius: 12px !important;
  font-family: "Inter" !important;
}
.textuals {
  font-size: 14px !important;
  color: #475467 !important;
}
.rightSides .ant-input.ant-input-disabled {
  color: #667085 !important;
}
.rightSides h6 {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #344054 !important;
}
.commisionTable table tbody td {
  padding: 24px 17px !important;
  font-family: "Inter" !important;
}
.commisionTable table tbody td:nth-child(1) {
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
}
.commisionTable table tbody td:nth-child(2) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(3) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(4) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(5) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(6) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionTable table tbody td:nth-child(7) span {
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.commisionSearch input {
  color: #000 !important;
  font-family: "Inter" !important;
}
.commisionSearch input::placeholder {
  color: #667085 !important;
}
.paginationButton button {
  font-family: "Inter" !important;
}
.tablesBox table tbody tr td:nth-child(3) span {
  font-size: 12px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}

.tablesBox .ant-table-wrapper thead tr th img {
  opacity: 0 !important;
}

.searchBoxed .ant-input-affix-wrapper input {
  color: #000 !important;
  font-family: "Inter" !important;
}

.searchBoxed .ant-input-affix-wrapper {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  width: 320px !important;
  border: 1px solid #d0d5dd !important;
}

.employeHeader {
  position: fixed;
  z-index: 99;
  background: #fff;
  width: 100%;
}

.generateTitle h1 {
  color: #101828;
  font-family: "Inter";
  font-size: 30px;
  font-weight: 600;
}

.generateTitle {
  padding: 17px 17px 0;
  display: flex;
  justify-content: space-between;
}

.lineGraph {
  background: #fff;
  border-radius: 12px;
  padding: 10px !important;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
}

.apexcharts-legend {
  justify-content: end !important;
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  display: none !important;
}
span.ant-dropdown-menu-title-content span {
  font-family: "Inter" !important;
}
.headers {
  font-family: "Inter" !important;
}
.ant-drawer-content.custom-drawer span {
  font-family: "Inter" !important;
}
button.ant-picker-year-btn {
  font-family: "Inter" !important;
  font-size: 16px !important;
}
